<template>
  <ObjectDetection
    :legends="shipLegends"
    :titleAxisY="titleAxisY"
    :iconCardDate="'mdi-ferry'"
    :listData="listData"
    :get-list-data="getListData"
  />
</template>
<script>
import { mapState } from '@/store/ults'
import ObjectDetection from './ObjectDetection.vue'
import { SHIP } from '@/constants/detection'
import ObjectModel from '@/models/object-detection/object'
import {getDataDetection} from "@/api/detection-api"

export default {
  components: {
    ObjectDetection,
  },
  data() {
    return {
      titleAxisY: SHIP,
      listData: []
    }
  },
  computed: {
    ...mapState('detection', ['shipLegends']),
  },
  methods: {
    async getListData(source, filters, currentAOI) {
      const payload = {
        ...filters,
        source,
        service_name: this.$route.meta.service,
      }
      const res = await getDataDetection({
        projectId: this.$route.params.id,
        aoiId: currentAOI.uuid,
        payload,
      })
      this.listData = res.data.map(data => new ObjectModel(data))
    }
  },
  mounted() {
    if (this.shipLegends.length === 0) {
      this.$store.dispatch('detection/getLegends', {service_name: this.$route.meta.service})
    }
  }
}
</script>
